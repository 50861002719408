// pages/User.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Avatar,
  Container,
  Alert,
  Checkbox,
  CardHeader,
  IconButton,
  LinearProgress,
  TextField,
  FormControlLabel,
  Button,
  CardActions,
  Link,
  CardMedia,
  Box,
} from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import api from '../services/api';
import { useSelector } from 'react-redux';
import Mark from '../components/Mark';
import QRCodeStyling from "qr-code-styling";
import PromotedCard from '../components/PromotedCard';

const Master = () => {
  const { token } = useSelector((state) => state.auth);
  const { masterPhone } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [qrImage, setQrImage] = useState();
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [wouldRecommend, setWouldRecommend] = useState(true);
  const [comment, setComment] = useState('');

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const url = masterPhone.match(/^\d{11}$/) ? `/masters/phone/${masterPhone}` : `/masters/${masterPhone}`;

        const response = await api.get(`${url}?from=${searchParams.get('from') || ''}`);

        setData(response.data);
      } catch (error) {
        setError(error.response?.data || error.message);
      }
    };

    if (masterPhone) {
      fetchRatings();
    }
  }, [masterPhone, searchParams, ratingSubmitted]);

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await api.post('/ratings', { ratedMasterId: data.id, wouldRecommend, comment });

      setRatingSubmitted(true);
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const handleShare = () => {
    const url = process.env.REACT_APP_CLIENT_URL + "/master/" + masterPhone;

    if (navigator.share) {
      navigator.share({
        title: `${data?.name} ${data?.lastName || ''}`,
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const options = {
    type: 'canvas',
    shape: "square",
    width: 600,
    height: 600,
    data: `${process.env.REACT_APP_CLIENT_URL}/master/${masterPhone}`,
    margin: 8,
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevel: "Q"
    },
    imageOptions: {
      saveAsBlob: true,
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0
    },
    dotsOptions: {
      type: "rounded",
      color: "#000",
      roundSize: true,
      gradient: null
    },
    backgroundOptions: {
      round: 0,
      color: "#ffffff"
    },
    image: '/static/client/1/favicon.svg',
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      }
    },
    cornersSquareOptions: {
      type: "extra-rounded",
      color: "#000"
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      }
    },
    cornersDotOptions: {
      type: "",
      color: "#000"
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      }
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      }
    }
  };

  const [qrCode] = useState(new QRCodeStyling(options));

  useEffect(() => {
    if (!qrCode) return;

    qrCode.getRawData('png').then((data) => {
      const blob = new Blob([data], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      setQrImage(url);
    });

  }, [qrCode]);

  const contact = (src) => {
    api.post('/masters/contact?masterId=' + data.id + '&source=' + src);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
  };

  if (!data) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <IconButton color='inherit' size='large' onClick={handleGoBack} aria-label="go back">
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>Загрузка...</Typography>
        </Stack>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={handleGoBack} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom noWrap>{data.name || 'Новый пользователь'} {data.lastName || ''}</Typography>
      </Stack>
      <Stack spacing={1} sx={{ mt: 2 }}>
        <PromotedCard markTypeIds={data.markTypeIds}>
          <CardHeader
            avatar={<Avatar
              src={data.photoUrl}
              alt={(data.name || 'Новый пользователь') + ' ' + (data.lastName || '')} />}
            title={(data.name || 'Новый пользователь') + ' ' + (data.lastName || '')}
          />
          <Slider {...settings}>
            {
              data.descriptionPhotos?.length > 0 &&
              [...data.descriptionPhotos].sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={item.filePath}
                    alt={(data.name || 'Новый пользователь') + ' ' + (data.lastName || '')} />
                ))
            }
            {
              qrImage &&
              <Box position="relative" key={99}>
                <CardMedia
                  component="img"
                  image={qrImage}
                  alt={(data.name || 'Новый пользователь') + ' ' + (data.lastName || '')}
                />
                <Box
                  component="img"
                  alt='Uustar.ru'
                  src="/static/client/1/favicon.svg"
                  position="absolute"
                  top="50%"
                  left="50%"
                  width="25%"
                  height="25%"
                  sx={{ transform: 'translate(-50%, -50%)' }}
                />
              </Box>
            }
          </Slider>
          <CardContent>
            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {data.description || ''}
            </Typography>
            <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
              {data.markTypeIds.map((markTypeId) => (
                <Mark key={markTypeId} markTypeId={markTypeId} />
              ))}
            </Stack>
          </CardContent>
          <CardActions>
            {data.phoneNumber && <Typography>
              <Link onClick={() => contact('profile_phone')} href={`tel:${data.phoneNumber}`} sx={{ mr: 1 }} variant="body1">
                +{data.phoneNumber}
              </Link>
              /
              <Link
                onClick={() => contact('profile_wa')}
                href={`https://wa.me/${data.phoneNumber}?text=Из%20uustar.ru/app/master/${data.phoneNumber}.%20`}
                sx={{ m: 1 }}
                target="_blank"
                variant="body1">
                WhatsApp
              </Link>
              /
              <Link
                onClick={() => contact('profile_tg')}
                href={`https://t.me/+${data.phoneNumber}?text=Из%20uustar.ru/app/master/${data.phoneNumber}.%20`}
                sx={{ m: 1 }}
                target="_blank"
                variant="body1">
                Telegram
              </Link>
            </Typography>}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton aria-label="share" onClick={handleShare} >
              <ShareIcon />
            </IconButton>
          </CardActions>
        </PromotedCard>

        {error && <Alert severity="error">{error}</Alert>}

        {!token && (<Alert severity="info">
          <Typography>Чтобы оставить отзыв о мастере, пожалуйста, <Link component={RouterLink} to={"/login?to=" + encodeURI('/master/' + masterPhone)}>войдите (регистрируйтесь)</Link>.</Typography>
        </Alert>)}

        {token && (
          <>
            {!ratingSubmitted && (
              <Card>
                <CardHeader title="Оставить отзыв" />
                <CardContent>
                  <form onSubmit={handleRatingSubmit}>
                    <FormControlLabel
                      control={<Checkbox
                        defaultChecked
                        value={wouldRecommend}
                        onChange={(e) => setWouldRecommend(e.target.checked)} />}
                      label="Рекомендую" />
                    <TextField
                      label="Комментарий"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      required
                      multiline
                    />
                    <Button variant='contained' type="submit">Оставить отзыв</Button>
                  </form>
                </CardContent>
              </Card>
            )}
            {ratingSubmitted && <Alert severity="success">Спасибо за отзыв!</Alert>}
          </>
        )}

        {data.ratings?.length > 0 ? (
          [...data.ratings]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((rating) => (
              <Card key={rating.createdAt}>
                <CardHeader
                  avatar={<Avatar
                    src={rating.reviewerPhotoUrl}
                    alt={`${rating.reviewerName}`} />}

                  title={`${rating.reviewerName}`}
                  subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                  action={<Typography variant="caption" color="text.secondary">
                    {new Date(rating.createdAt).toLocaleDateString()}
                  </Typography>}
                  sx={{ pb: 0 }}
                />
                <CardContent>
                  <Typography variant="body1">{rating.comment}</Typography>
                </CardContent>
              </Card>
            ))
        ) : (
          <Typography></Typography>
        )}
      </Stack>
    </Container>
  );
}

export default Master;