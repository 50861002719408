import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

const PromotedCard = ({ children, markTypeIds }) => {
  const getPromotionStatus = (markTypeIds) => {
    const hasPassport = markTypeIds?.includes(1);
    const hasTrending = markTypeIds?.includes(2);

    if (hasPassport && hasTrending) {
      return 'combo';
    } else if (hasPassport) {
      return 'passport';
    } else if (hasTrending) {
      return 'trending';
    } else {
      return 'none';
    }
  };

  const promotionStyles = {
    none: {}, // Без стилей для обычных откликов

    trending: {
      border: '2px solid #FFD700', // Золотая граница
      boxShadow: '0 0 8px rgba(255, 215, 0, 0.3)',
    },

    passport: {
      border: '2px solid #4CAF50', // Зеленая граница
      boxShadow: '0 4px 12px rgba(76, 175, 80, 0.2)',
    },

    combo: {
      border: '4px solid transparent',
      background: `
        linear-gradient(145deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%),
        linear-gradient(145deg, #FF5722 0%, #FFC107 100%)
      `,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
      animation: 'combo-glow 1.5s infinite alternate',
      '@keyframes combo-glow': {
        '0%': {
          boxShadow: '0 0 12px rgba(255, 87, 34, 1)',
          opacity: 0.9
        },
        '100%': {
          boxShadow: '0 0 24px rgba(255, 193, 7, 1)',
          opacity: 1
        }
      }
    }
  };

  return (
    <Card
      sx={{
        transition: 'all 0.3s ease',
        ...promotionStyles[getPromotionStatus(markTypeIds)]
      }}>
      {children}
    </Card>
  );
};

PromotedCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PromotedCard;