// pages/Profile.js
import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Typography,
  Stack,
  Box,
  Avatar,
  Container,
  Alert,
  Link,
  IconButton,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  isPersonalNotificationsEnabled,
  disablePersonalNotifications,
  enablePersonalNotifications
} from '../services/pushy';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../slices/listingsSlice';
import { logout } from '../slices/authSlice';
import api from '../services/api';
import CatImageComponent from '../components/CatImageComponent';

const Profile = () => {
  const { profile, loading, error } = useSelector((state) => state.listings);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [settingsError, setSettingsError] = useState(null);
  const [switchingNotifications, setSwitchingNotifications] = useState(false);
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(isPersonalNotificationsEnabled());

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const handleNotificationsChange = async (event) => {
    try {
      const enabled = isPersonalNotificationsEnabled();
      setSwitchingNotifications(true);

      if (enabled) {
        await disablePersonalNotifications();
      } else {
        await enablePersonalNotifications({ onAuthFail: () => dispatch(logout()) });
      }
    } catch (error) {
      setSettingsError(error.response?.data || error.message);
    } finally {
      setIsNotificationsEnabled(isPersonalNotificationsEnabled());
      setSwitchingNotifications(false);
    }
  };

  const testNotification = async () => {
    try {
      setSwitchingNotifications(true);

      await enablePersonalNotifications({ onAuthFail: () => dispatch(logout()) });

      await api.post('/deviceTokens/test', null);
    } catch (error) {
      setSettingsError(error.response?.data || error.message);
    } finally {
      setSwitchingNotifications(false);
    }
  };

  const ios = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Typography variant="h4" gutterBottom>Профиль</Typography>

      {loading && <LinearProgress />}

      <Stack spacing={1}>
        <Stack direction={'row'} alignItems="center" spacing={1}>
          <Avatar
            src={profile?.photoUrl}
            alt={profile?.name}
          />
          <Stack>
            <Typography variant="subtitle1">
              {profile?.name}
            </Typography>
            <Typography variant="caption">
              +{profile?.phoneNumber}
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }} />
          <IconButton aria-label="edit" onClick={() => navigate(`/edit-profile`)} >
            <EditIcon />
          </IconButton>
        </Stack>

        {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}

        <Button endIcon={<LogoutIcon />} variant='outlined' onClick={() => dispatch(logout())}>
          Выйти
        </Button>

        <Link href={process.env.REACT_APP_MASTER_URL}>
          Переключиться в режим мастера
        </Link>

        <Link href="https://t.me/profisfera_ru" target="_blank">
          Написать в поддержку
        </Link>

        <Link component={RouterLink} to="/privacy">
          Политика в отношении обработки персональных данных
        </Link>

      </Stack>

      <Stack sx={{ my: 1 }} spacing={1}>
        {settingsError && (settingsError.includes('Add to Home')
          ? <Alert severity="warning">
            Для получения уведомлений,<br /><Link component={RouterLink} to="/install-app">установите приложение на домашний экран</Link>.</Alert>
          : (settingsError.includes('permission')
            ? (ios
              ? <Alert severity="warning">Разрешите приложению получать уведомления в настройках системы.</Alert>
              : <Alert severity="warning">Разрешите уведомления в настройках браузера.</Alert>)
            : <Alert severity="error">{settingsError}</Alert>))}

        {isNotificationsEnabled &&
          (<Alert severity="info">
            {ios
              ? 'Если уведомления не приходят, разрешите приложению получать уведомления в настройках системы.'
              : 'Если уведомления не приходят, разрешите уведомления в настройках браузера.'}
          </Alert>)}

        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isNotificationsEnabled} disabled={switchingNotifications} onChange={handleNotificationsChange} />}
            label="Включить персональные уведомления" />

          {isNotificationsEnabled && (
            <Button variant='outlined' onClick={testNotification} disabled={switchingNotifications} size='small' >
              Получить тестовое уведомление
            </Button>
          )}
        </FormGroup>

        {profile && <CatImageComponent data={profile.stats} />}

        <Typography
          variant="body2"
          sx={{
            fontFamily: 'monospace',
            fontSize: '0.75rem',
          }}>
          {localStorage.getItem('pushyToken')}
        </Typography>
      </Stack>

    </Container >
  );
};

export default Profile;
