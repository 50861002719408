// App.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, Link as RouterLink } from 'react-router-dom';
import Navbar from './components/Navbar';
import Listings from './pages/Listings';
import Login from './pages/Login';
import { refreshToken } from './services/pushy';
import Pushy from 'pushy-sdk-web';
import { upsertListing, fetchActiveMasters } from './slices/listingsSlice';
import { Box, createTheme, CssBaseline, Link, ThemeProvider, Typography } from '@mui/material';
import CreateListing from './pages/CreateListing';
import Profile from './pages/Profile';
import ListingDetail from './pages/ListingDetail';
import Master from './pages/Master';
import Privacy from './pages/Privacy';
import InstallApp from './pages/InstallApp';
import EditProfile from './pages/EditProfile';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (window.ym) {
      window.ym(process.env.REACT_APP_YANDEX_METR, 'hit', '/app' + location.pathname);
    }
  }, [location]);

  useEffect(() => {
    // dispatch(fetchActiveMasters());

    Pushy.setNotificationListener((data) => {
      console.log('Received notification: ' + JSON.stringify(data));

      if (data.extra) {
        const extra = JSON.parse(data.extra);

        if (extra.listing)
          dispatch(upsertListing(extra.listing));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    refreshToken();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#ff5722',
      },
      secondary: {
        main: '#03a9f4',
        contrastText: '#fff',
      },
    },
    components: {
      MuiCard: {
        defaultProps: {
          elevation: 8,
        },
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 8,
        },
      },
      MuiAvatar: {
        defaultProps: {
          variant: 'rounded',
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
            width: 64,
            height: 64,
          },
        },
      },
    },
    shadows: [
      "none",
      "0px 2px 1px -1px rgba(0,0,0,0.05),0px 1px 1px 0px rgba(0,0,0,0.035),0px 1px 3px 0px rgba(0,0,0,0.03)",
      "0px 3px 1px -2px rgba(0,0,0,0.05),0px 2px 2px 0px rgba(0,0,0,0.035),0px 1px 5px 0px rgba(0,0,0,0.03)",
      "0px 3px 3px -2px rgba(0,0,0,0.05),0px 3px 4px 0px rgba(0,0,0,0.035),0px 1px 8px 0px rgba(0,0,0,0.03)",
      "0px 2px 4px -1px rgba(0,0,0,0.05),0px 4px 5px 0px rgba(0,0,0,0.035),0px 1px 10px 0px rgba(0,0,0,0.03)",
      "0px 3px 5px -1px rgba(0,0,0,0.05),0px 5px 8px 0px rgba(0,0,0,0.035),0px 1px 14px 0px rgba(0,0,0,0.03)",
      "0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.035),0px 1px 18px 0px rgba(0,0,0,0.03)",
      "0px 4px 5px -2px rgba(0,0,0,0.05),0px 7px 10px 1px rgba(0,0,0,0.035),0px 2px 16px 1px rgba(0,0,0,0.03)",
      "0px 5px 5px -3px rgba(0,0,0,0.05),0px 8px 10px 1px rgba(0,0,0,0.035),0px 3px 14px 2px rgba(0,0,0,0.03)",
      "0px 5px 6px -3px rgba(0,0,0,0.05),0px 9px 12px 1px rgba(0,0,0,0.035),0px 3px 16px 2px rgba(0,0,0,0.03)",
      "0px 6px 6px -3px rgba(0,0,0,0.05),0px 10px 14px 1px rgba(0,0,0,0.035),0px 4px 18px 3px rgba(0,0,0,0.03)",
      "0px 6px 7px -4px rgba(0,0,0,0.05),0px 11px 15px 1px rgba(0,0,0,0.035),0px 4px 20px 3px rgba(0,0,0,0.03)",
      "0px 7px 8px -4px rgba(0,0,0,0.05),0px 12px 17px 2px rgba(0,0,0,0.035),0px 5px 22px 4px rgba(0,0,0,0.03)",
      "0px 7px 8px -4px rgba(0,0,0,0.05),0px 13px 19px 2px rgba(0,0,0,0.035),0px 5px 24px 4px rgba(0,0,0,0.03)",
      "0px 7px 9px -4px rgba(0,0,0,0.05),0px 14px 21px 2px rgba(0,0,0,0.035),0px 5px 26px 4px rgba(0,0,0,0.03)",
      "0px 8px 9px -5px rgba(0,0,0,0.05),0px 15px 22px 2px rgba(0,0,0,0.035),0px 6px 28px 5px rgba(0,0,0,0.03)",
      "0px 8px 10px -5px rgba(0,0,0,0.05),0px 16px 24px 2px rgba(0,0,0,0.035),0px 6px 30px 5px rgba(0,0,0,0.03)",
      "0px 8px 11px -5px rgba(0,0,0,0.05),0px 17px 26px 2px rgba(0,0,0,0.035),0px 6px 32px 5px rgba(0,0,0,0.03)",
      "0px 9px 11px -5px rgba(0,0,0,0.05),0px 18px 28px 2px rgba(0,0,0,0.035),0px 7px 34px 6px rgba(0,0,0,0.03)",
      "0px 9px 12px -6px rgba(0,0,0,0.05),0px 19px 29px 2px rgba(0,0,0,0.035),0px 7px 36px 6px rgba(0,0,0,0.03)",
      "0px 10px 13px -6px rgba(0,0,0,0.05),0px 20px 31px 3px rgba(0,0,0,0.035),0px 8px 38px 7px rgba(0,0,0,0.03)",
      "0px 10px 13px -6px rgba(0,0,0,0.05),0px 21px 33px 3px rgba(0,0,0,0.035),0px 8px 40px 7px rgba(0,0,0,0.03)",
      "0px 10px 14px -6px rgba(0,0,0,0.05),0px 22px 35px 3px rgba(0,0,0,0.035),0px 8px 42px 7px rgba(0,0,0,0.03)",
      "0px 11px 14px -7px rgba(0,0,0,0.05),0px 23px 36px 3px rgba(0,0,0,0.035),0px 9px 44px 8px rgba(0,0,0,0.03)",
      "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.035),0px 9px 46px 8px rgba(0,0,0,0.03)",
    ],
  });

  const ProtectedRoute = ({ children }) => token ? children : <Navigate to={`/login?to=${encodeURIComponent(location.pathname + location.search)}`} />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{ bgcolor: '#fafafa' }}>
        <Box sx={{ flexGrow: 1, pb: token ? '96px' : 2 }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute><Listings /></ProtectedRoute>} />
            <Route path="/create-listing/:id?" element={<ProtectedRoute><CreateListing /></ProtectedRoute>} />
            <Route path="/listing/:id" element={<ProtectedRoute><ListingDetail /></ProtectedRoute>} />
            <Route path="/master/:masterPhone" element={<Master />} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/install-app" element={<InstallApp />} />
            <Route path="/install" element={<InstallApp />} />
          </Routes>
        </Box>

        {
          token
            ? <Navbar />
            : <>
              {/* Footer */}
              <Box sx={{ mt: 'auto', py: 3, textAlign: 'center', backgroundColor: '#333', color: '#fff' }}>
                <Typography variant="body2" color="inherit">
                  ИП Семенов Виктор Петрович. ОГРНИП 322140000027095. ИНН 141901798610.
                </Typography>
                <Typography variant="body2" color="inherit">
                  <Link component={RouterLink} to="/create-listing">
                    Создать&nbsp;новый&nbsp;заказ
                  </Link> | <Link component={RouterLink} to="/login">
                    Войти
                  </Link> | <Link component={RouterLink} to="/privacy">
                    Политика&nbsp;конфиденциальности
                  </Link> | <Link href="https://t.me/profisfera_ru" target="_blank">
                    Наш&nbsp;Телеграм
                  </Link>
                </Typography>
              </Box>
            </>
        }
      </Box>
    </ThemeProvider>
  );
};

export default App;
