import * as React from 'react';
import { Chip, Grid2, Stack } from '@mui/material';

const Category = ({ categoryId }) => {
  const group = {
    '49': 'Услуги автосервиса',
    '50': 'Услуги автосервиса',
    '51': 'Услуги автосервиса',
    '52': 'Услуги автосервиса',
    '53': 'Услуги автосервиса',
    '54': 'Услуги автосервиса',
    '55': 'Услуги автосервиса',
    '56': 'Услуги автосервиса',
    '57': 'Услуги автосервиса',
    '58': 'Услуги автосервиса',
    '59': 'Услуги автосервиса',
    '60': 'Услуги автосервиса',
    '61': 'Услуги автосервиса',
    '62': 'Услуги автосервиса',

    '37': 'Услуги сантехника',
    '38': 'Услуги сантехника',
    '39': 'Услуги сантехника',
    '40': 'Услуги сантехника',
    '41': 'Услуги сантехника',

    '42': 'Уборка помещений и территорий',
    '43': 'Уборка помещений и территорий',
    '44': 'Уборка помещений и территорий',
    '45': 'Уборка помещений и территорий',
    '46': 'Уборка помещений и территорий',
    '48': 'Уборка помещений и территорий',

    '27': 'Строительство и ремонт',
    '28': 'Строительство и ремонт',
    '29': 'Строительство и ремонт',
    '30': 'Строительство и ремонт',
    '31': 'Строительство и ремонт',
    '32': 'Строительство и ремонт',
    '33': 'Строительство и ремонт',
    '34': 'Строительство и ремонт',
    '35': 'Строительство и ремонт',
    
    '6': 'Транспортировка',
    '36': 'Транспортировка',
    '63': 'Транспортировка',
    '64': 'Транспортировка',
    '65': 'Транспортировка',
    '78': 'Транспортировка',
    '79': 'Транспортировка',

    '66': 'Спецтехника',
    '67': 'Спецтехника',
    '68': 'Спецтехника',
    '69': 'Спецтехника',
    '70': 'Спецтехника',
    '71': 'Спецтехника',
    '72': 'Спецтехника',
    '73': 'Спецтехника',
    '74': 'Спецтехника',
    '75': 'Спецтехника',
    '76': 'Спецтехника',
    '77': 'Спецтехника',
  };

  const text = {
    '1': 'Без категории',
    '2': 'Строительство и ремонт',
    '3': 'Сантехники',
    '4': 'Электрики',
    '5': 'Ремонт и установка техники',
    '6': 'Грузчики',
    '7': 'Спецтехника',
    '8': 'Транспортировка',
    '9': 'Автосервис',
    '10': 'Бухгалтерия и финансы',
    '11': 'Детские сады, няни, сиделки',
    '12': 'Красота и здоровье',
    '13': 'Курсы, семинары, тренинги',
    '14': 'Мастер на час',
    '15': 'Мебель. Ремонт и изготовление',
    '16': 'Обучение, репетиторство',
    '17': 'Пошив, ремонт одежды, обуви',
    '18': 'Праздники и мероприятия',
    '19': 'Работа с текстом, переводы',
    '20': 'Реклама и полиграфия',
    '21': 'Ремонт компьютеров, мобильных устройств',
    '22': 'Слесари',
    '23': 'Создание сайтов, поддержка ПО',
    '24': 'Уборка помещений, территорий',
    '25': 'Фото- и видеосъёмка',
    '26': 'Юридические услуги',

    '27': 'Ремонт квартир и помещений',
    '28': 'Окна и двери',
    '29': 'Строительство',
    '30': 'Газификация',
    '31': 'Натяжные потолки',
    '32': 'Автоматические ворота',
    '33': 'Сварочные работы',
    '34': 'Отделочные работы',
    '35': 'Сметы и проектирование',
    '36': 'Доставка стройматериалов',
    '37': 'Разморозка домов, гаражей, теплотрасс',
    '38': 'Устранение засоров канализации',
    '39': 'Установка сантехнического оборудования',
    '40': 'Монтаж систем отопления и водоснабжения',
    '41': 'Срочные услуги сантехников и сварщиков',

    '42': 'Уборка территорий. Вывоз строительного мусора',
    '43': 'Вывоз снега',
    '44': 'Уборка помещений, клининг',
    '45': 'Химчистка мебели',
    '46': 'Дезинсекция',
    '47': 'Монтаж систем видеонаблюдения',

    '48': 'Вынос бытового мусора',

    '49': 'Разогрев авто',
    '50': 'Вскрытие авто',
    '51': 'Автоэлектрика',
    '52': 'Автодиагностика',
    '53': 'Ремонт автомобилей',
    '54': 'Кузовной ремонт',
    '55': 'Шиномонтаж и сход-развал',
    '56': 'Полировка и химчистка',
    '57': 'Тонировка и утепление авто',
    '58': 'Автозапчасти и подбор авто',
    '59': 'Сварочные работы',
    '60': 'Техпомощь на дороге',
    '61': '3D-печать и кастомизация',
    '62': 'Сушка портативных гаражей',

    '63': 'Грузоперевозки',
    '64': 'Перегон автомобилей',
    '65': 'Такси межгород',

    '66': "Манипуляторы",
    '67': "Автокраны",
    '68': "Автовышки",
    '69': "Эвакуаторы",
    '70': "Фекалки и ассенизаторы",
    '71': "Самосвалы",
    '72': "Погрузчики",
    '73': "Экскаваторы",
    '74': "Буровые установки",
    '75': "Водовозки",
    '76': "Тралы и низкорамные платформы",
    '77': "Аренда оборудования",

    '78': 'Такси по городу',
    '79': 'Почасовое такси',

    '80': 'Социальная парикмахерская',
  };

  const bgColor = {
    // '1': '#000000',
    '2': '#FF0000',
    '3': '#FF4500',
    '4': '#FF8C00',
    '5': '#FFD700',
    '6': '#ADFF2F',
    '7': '#008000',
    '8': '#006400',
    '9': '#0000FF',
    '10': '#4B0082',
    '11': '#EE82EE',
    '12': '#800080',
    '13': '#FF1493',
    '14': '#FF69B4',
    '15': '#FFA07A',
    '16': '#FF4500',
    '17': '#FF8C00',
    '18': '#FFD700',
    '19': '#ADFF2F',
    '20': '#008000',
    '21': '#006400',
    '22': '#0000FF',
    '23': '#4B0082',
    '24': '#EE82EE',
    '25': '#800080',
    '26': '#FF1493',
    '27': '#FF69B4',
    '28': '#FFA07A',
    '29': '#FF4500',
    '30': '#FF8C00',
    '31': '#FFD700',
    '32': '#ADFF2F',
    '33': '#008000',
    '34': '#006400',
    '35': '#0000FF',
    '36': '#4B0082',
    '37': '#EE82EE',
    '38': '#800080',
    '39': '#FF1493',
    '40': '#FF69B4',
    '41': '#FFA07A',
    '42': '#FF4500',
    '43': '#FF8C00',
    '44': '#FFD700',
    '45': '#ADFF2F',
    '46': '#008000',
    '47': '#006400',
    '48': '#0000FF',
    '49': '#4B0082',
    '50': '#EE82EE',
    '51': '#800080',
    '52': '#FF1493',
    '53': '#FF69B4',
    '54': '#FFA07A',
    '55': '#FF4500',
    '56': '#FF8C00',
    '57': '#FFD700',
    '58': '#ADFF2F',
    '59': '#008000',
    '60': '#006400',
    '61': '#0000FF',
    '62': '#4B0082',
    '63': '#EE82EE',
    '64': '#800080',
    '65': '#FF1493',
    '66': '#FF69B4',
    '67': '#FFA07A',
    '68': '#FF4500',
    '69': '#FF8C00',
    '70': '#FFD700',
    '71': '#ADFF2F',
    '72': '#008000',
    '73': '#006400',
    '74': '#0000FF',
    '75': '#4B0082',
    '76': '#EE82EE',
    '77': '#800080',
    '78': '#FF1493',
    '79': '#FF69B4',
    '80': '#FFA07A',
  };

  const fgColor = {
    // '1': '#FFFFFF',
    '2': '#FFFFFF',
    '3': '#FFFFFF',
    '4': '#FFFFFF',
    '5': '#000000',
    '6': '#000000',
    '7': '#FFFFFF',
    '8': '#FFFFFF',
    '9': '#FFFFFF',
    '10': '#FFFFFF',
    '11': '#000000',
    '12': '#FFFFFF',
    '13': '#FFFFFF',
    '14': '#000000',
    '15': '#000000',
    '16': '#FFFFFF',
    '17': '#000000',
    '18': '#000000',
    '19': '#000000',
    '20': '#FFFFFF',
    '21': '#FFFFFF',
    '22': '#FFFFFF',
    '23': '#FFFFFF',
    '24': '#000000',
    '25': '#FFFFFF',
    '26': '#FFFFFF',
    '27': '#FFFFFF',
    '28': '#FFFFFF',
    '29': '#FFFFFF',
    '30': '#FFFFFF',
    '31': '#000000',
    '32': '#000000',
    '33': '#FFFFFF',
    '34': '#FFFFFF',
    '35': '#FFFFFF',
    '36': '#FFFFFF',
    '37': '#FFFFFF',
    '38': '#FFFFFF',
    '39': '#FFFFFF',
    '40': '#FFFFFF',
    '41': '#FFFFFF',
    '42': '#FFFFFF',
    '43': '#FFFFFF',
    '44': '#000000',
    '45': '#000000',
    '46': '#FFFFFF',
    '47': '#FFFFFF',
    '48': '#FFFFFF',
    '49': '#FFFFFF',
    '50': '#FFFFFF',
    '51': '#FFFFFF',
    '52': '#FFFFFF',
    '53': '#FFFFFF',
    '54': '#FFFFFF',
    '55': '#FFFFFF',
    '56': '#FFFFFF',
    '57': '#FFFFFF',
    '58': '#000000',
    '59': '#000000',
    '60': '#FFFFFF',
    '61': '#FFFFFF',
    '62': '#FFFFFF',
    '63': '#FFFFFF',
    '64': '#FFFFFF',
    '65': '#FFFFFF',
    '66': '#FFFFFF',
    '67': '#FFFFFF',
    '68': '#FFFFFF',
    '69': '#FFFFFF',
    '70': '#FFFFFF',
    '71': '#FFFFFF',
    '72': '#FFFFFF',
    '73': '#FFFFFF',
    '74': '#FFFFFF',
    '75': '#FFFFFF',
    '76': '#FFFFFF',
    '77': '#FFFFFF',
    '78': '#FFFFFF',
    '79': '#FFFFFF',
    '80': '#FFFFFF',
  };

  return (
    <Grid2 container spacing={1}>
      {group[categoryId] && <Grid2 key={0}>
        <Chip label={group[categoryId]} size="small" />
      </Grid2>}
      <Grid2 key={1}>
        <Chip label={text[categoryId]} size="small" sx={{ backgroundColor: bgColor[categoryId], color: fgColor[categoryId] }} />
      </Grid2>
    </Grid2>
  );
}

export default Category;
