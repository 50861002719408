import React, { useState, useEffect } from "react";
import { Paper, Box, IconButton, Stack, Typography, Container } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const InstallPwa = () => {
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const navigate = useNavigate();

  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installApp = async () => {
    if (!deferredPrompt) {
      navigate('/install-app');
      return;
    }

    deferredPrompt.prompt();

    setDeferredPrompt(null);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("Приложение установлено");
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  useEffect(() => {
    setIsInStandaloneMode(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  if (isInStandaloneMode && !isIframe) {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ px: 0 }}>
      <Paper>
        <Stack direction={'row'} sx={{ p: 1 }} spacing={1} alignItems={"center"}>
          <Box
            component="img"
            src="/static/client/1/apple-touch-icon.png"
            alt="Uustar"
            width={48}
            height={48}
            sx={{ borderRadius: 3 }}
          />
          <Stack>
            <Typography variant="h6">Uustar.ru</Typography>
            <Typography variant="caption">Установите приложение на телефон, чтобы получать <b>уведомления</b></Typography>
          </Stack>
          <Box flexGrow={1} />

          {
            isIframe ?
              <IconButton aria-label="download" size="large" color="secondary" LinkComponent={RouterLink} to="/install" target="_blank">
                <GetAppRoundedIcon fontSize="inherit" />
              </IconButton> :
              <IconButton aria-label="download" size="large" color="secondary" onClick={installApp}>
                <GetAppRoundedIcon fontSize="inherit" />
              </IconButton>
          }

        </Stack>
      </Paper>
    </Container>
  );
};

export default InstallPwa;
