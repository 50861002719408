// pages/ListingDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListing } from '../slices/listingsSlice';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  IconButton,
  Stack,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  CardHeader,
  CardMedia,
  CardActions,
  Link,
  LinearProgress,
  TextField
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Offers from '../components/Offers';
import Category from '../components/Category';
import api from '../services/api';
import InstallPwa from '../components/InstallPwa';

const ListingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listing = useSelector((state) =>
    state.listings.listings.find((l) => l.id === id)
  );
  const { userId } = useSelector((state) => state.auth);
  const location = useLocation();

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchListing({ id }));
  }, [id, dispatch]);

  const handleCloseListing = async (listingId) => {
    try {
      await api.put(`/listings/${listingId}/close`);

      dispatch(fetchListing({ id }));
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const handleConfirmCloseListing = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleShare = () => {
    const url = process.env.REACT_APP_CLIENT_URL + "/listing/" + id;

    if (navigator.share) {
      navigator.share({
        title: listing?.title || 'Uustar',
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
  };

  if (!listing) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          <Stack direction='row' spacing={1} alignItems='baseline'>
            <IconButton color='inherit' size='large' onClick={handleGoBack} aria-label="go back">
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom noWrap>Загрузка...</Typography>
          </Stack>
          <LinearProgress />
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <InstallPwa />
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          <Stack direction='row' spacing={1} alignItems='baseline'>
            <IconButton color='inherit' size='large' onClick={handleGoBack} aria-label="go back">
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom noWrap>{listing.title}</Typography>
          </Stack>
          <Card key={listing.id}>

            <CardHeader
              avatar={<Avatar
                src={listing.photoUrl}
                alt={listing.name} />}

              title={listing.name}
              action={<Typography variant="caption" color="text.secondary">
                {new Date(listing.createdAt).toLocaleDateString()}
              </Typography>}
            />

            {(listing.photos?.length > 0 || listing.videoUrl) &&
              <Slider {...settings}>
                {listing.videoUrl &&
                  <CardMedia
                    component="video"
                    controls
                    src={listing.videoUrl}
                    alt={listing.title}
                    loop
                    style={{ width: '100%' }} />
                }
                {listing.photos?.length > 0 && [...listing.photos].sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <CardMedia
                      key={index}
                      component="img"
                      image={item.filePath}
                      alt={listing.title} />
                  ))}
              </Slider>
            }

            <CardContent>
              <Typography variant="subtitle1">
                Воспользуюсь услугой
              </Typography>
              <Typography variant="h6" gutterBottom>
                {listing.title}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ whiteSpace: 'pre-line' }}>
                {listing.description}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {listing.location}{listing.destination && ' - '}{listing.destination}
              </Typography>
              {listing.askedPrice && <Typography variant="body1" gutterBottom>
                {listing.askedPrice} руб.
              </Typography>}
              <Category categoryId={listing.categoryId} />

              {!listing.isClosed && listing.creatorId === userId && <Alert sx={{ mt: 1 }} severity="info">🔒 Ваш номер телефона доступен только тем, кому вы напишете, и выбранному исполнителю.</Alert>}
              {listing.hasAcceptedOffer && listing.creatorId === userId && <Alert sx={{ mt: 1 }} severity="success">Будем признательны, если оставите отзыв на странице мастера.</Alert>}
              {listing.isClosed && <Alert sx={{ mt: 1 }} severity="info">Прием предложений завершен</Alert>}
              {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}
            </CardContent>
            <CardActions>
              {!listing.isClosed && listing.creatorId === userId &&
                <Button variant='contained' size="small" onClick={handleConfirmCloseListing}>Закрыть</Button>
              }

              <Box sx={{ flexGrow: 1 }} />

              <IconButton aria-label="share" onClick={handleShare} >
                <ShareIcon />
              </IconButton>
              {!listing.isClosed && listing.creatorId === userId &&
                <IconButton aria-label="edit" onClick={() => navigate(`/create-listing/${id}`)} >
                  <EditIcon />
                </IconButton>}
            </CardActions>
          </Card>
          <Offers listing={listing} />
        </Stack>
        <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handleCloseListing(listing.id);
              handleConfirmDialogClose();
            },
          }}
        >
          <DialogTitle>Закрыть заказ</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {listing.offers?.length > 0 && <>
                Внимание! Все предложения будут удалены. Примите предложение, чтобы сохранить контакт мастера.
              </>}
              <br />
              <>
                Продолжить?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleConfirmDialogClose} color="primary">
              Отмена
            </Button>
            <Button
              variant='contained'
              type="submit"
              color="secondary"
              autoFocus>
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default ListingDetail;
