import Pushy from 'pushy-sdk-web';
import api from './api';

export const enablePersonalNotifications = async ({ onAuthFail }) => {
  if (!Pushy.isRegistered()) {
    await register();
  }

  const deviceToken = localStorage.getItem('pushyToken');

  try {
    await api.post('/devicetokens', { token: deviceToken }, { skipAuthInterceptor: true });

    localStorage.setItem('isPersonalNotificationsEnabled', 'true');
  } catch (error) {
    if (error.response && error.response.status === 401) {
      onAuthFail && onAuthFail();

      return;
    }

    throw error;
  }
};

export const refreshToken = async () => {
  if (Pushy.isRegistered()) {
    const deviceToken1 = localStorage.getItem('pushyToken');

    try {
      await register();
    }
    catch (error) {
      console.error('Failed to refresh the device token', error);
      return;
    }

    const deviceToken2 = localStorage.getItem('pushyToken');

    if (deviceToken1 !== deviceToken2) {
      if (isPersonalNotificationsEnabled()) {
        await disablePersonalNotifications();
        await enablePersonalNotifications({});
      }
    }
  }
};

const register = async () => {
  try {
    const token = await Pushy.register({ appId: process.env.REACT_APP_PUSHY_APP_ID });
    return token;
  } catch (error) {
    console.error('Failed to register the device token', error);
    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
    throw error;
  }
};

export const disablePersonalNotifications = async () => {
  const deviceToken = localStorage.getItem('pushyToken');

  if (deviceToken) {
    await api.delete(`/devicetokens/${deviceToken}`, {
      skipAuthInterceptor: true,
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });

    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
  }
};

export const isPersonalNotificationsEnabled = () => localStorage.getItem('isPersonalNotificationsEnabled') === 'true';
