import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CatImageComponent = ({ data }) => {
  // Суммируем все значения объекта
  const sum = Object.values(data).reduce((acc, val) => acc + val, 0);

  // Ограничиваем максимальное значение до 9
  const limitedSum = Math.min(sum, 9);

  // Формируем путь к изображению
  const imageUrl = `/static/client/1/cat-${limitedSum}.png`;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography component="span">Уровень активности: {limitedSum}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <img
          src
          alt={`Cat ${limitedSum}`}
          srcSet={imageUrl}
          style={{ width: '100%', height: 'auto' }}
        />
        <Typography variant="body2" color="text.secondary">
          Если будете принимать предложения и оставлять отзывы, котенок получит настоящий замок!
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CatImageComponent;