// pages/CreateListing.js
import React, { useEffect, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  Stack,
  IconButton,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CardMedia,
  CardActions,
  CardHeader,
  Card,
  CardContent,
  ListSubheader
} from '@mui/material';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import api from '../services/api';

const CreateListing = () => {
  const [description, setDescription] = useState('');
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState(searchParams.get('title') || '');
  const [location, setLocation] = useState('');
  const [destination, setDestination] = useState('');
  const [categoryId, setCategoryId] = useState(searchParams.get('categoryId') || '');
  const [askedPrice, setAskedPrice] = useState('');
  const [photos, setPhotos] = useState([]);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const listing = useSelector((state) =>
    state.listings.listings.find((l) => l.id === id)
  );

  const routeLocation = useLocation();

  const handleGoBack = () => {
    if (routeLocation.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (listing) {
      setTitle(listing.title || '');
      setDescription(listing.description || '');
      setLocation(listing.location || '');
      setDestination(listing.destination || '');
      setCategoryId(listing.categoryId);
      setAskedPrice(listing.askedPrice || '');
      setPhotoUrls(listing.photos.map((photo) => photo.filePath));
    }
  }, [listing]);

  const catDescriptions = {
    "37": "Разморозка труб. Отогрев домов, гаражей, теплотрасс. Пропарка паром (ППУ).",
    "38": "Устранение засоров. Прочистка канализации паром.",
    "41": "Аварийные вызовы 24/7. Срочные услуги сантехников и сварщиков.",
    "4": "Установка и замена розеток, выключателей, люстр. Электрик 24 часа. Квартирный и частный электромонтаж. Подключение от столба к дому. Работы по КИПиА и слаботочным системам.",
    "27": "Ремонт квартир \"под ключ\". Косметический ремонт. Капитальный ремонт. Ремонт офисов и частных домов. Отделочные работы. Ремонт санузлов, ванных комнат. Покраска, шпаклевка, поклейка обоев.",
    "28": "Ремонт окон и дверей (пластиковых, балконных). Утепление окон и дверей. Регулировка и замена фурнитуры окон. Подготовка окон к зиме. Изготовление и установка окон и дверей. Полное обслуживание окон.",
    "29": "Строительство домов, коттеджей, бань. Фундаменты (отсыпка, планировка). Каркасные дома. Проектирование зданий и сооружений. Строительство из кирпича. Монтаж металлоконструкций.",
    "30": "Газификация \"под ключ\". Установка и ремонт котлов. Проектирование и монтаж систем газоснабжения.",
    "31": "Установка натяжных потолков. Потолки премиум качества.",
    "32": "Ремонт автоматических ворот. Установка секционных ворот.",
    "33": "Услуги сварщиков. Выездные сварочные работы. Монтаж металлоконструкций.",
    "34": "Кафель, керамогранит, плиточные работы. Установка межкомнатных дверей. Установка перегородок (офисные, алюминиевые, пластиковые).",
    "35": "Составление смет (КС-2, КС-3). Проектирование зданий и инженерных сетей.",
    "36": "Доставка угля, песка, щебня. Доставка других стройматериалов.",
    "39": "Установка и замена смесителей, кранов, раковин, унитазов, ванн. Установка инсталляций для унитазов. Подключение стиральных и посудомоечных машин.",
    "40": "Монтаж систем отопления. Установка радиаторов отопления. Монтаж теплых полов. Сварка пластиковых труб. Сварочные работы для сантехнических систем.",
    "5": "Ремонт и установка кондиционеров, стиральных машин, холодильников, посудомоечных машин, электрических плит и микроволновок. Ремонт и установка бытовой техники с выездом мастера на дом.",
    "6": "Для переезда, погрузки, разгрузки",
    "14": "Мелкий ремонт, монтаж, демонтаж, сборка мебели и т.п.. Вскрытие и установка замков. Установка карнизов, кронштейнов, полок и турников.",
    "42": "Вывоз мусора, мебели, строительного мусора. Уборка территории. Ручная погрузка и выгрузка.",
    "43": "Вывоз снега. Уборка снега. Сброс снега с крыши.",
    "44": "Услуги клининга, уборки квартир, частных домов, офисов, гаражей. Комплексная, генеральная, экспресс уборка.",
    "15": "Изготовление корпусной мебели на заказ. Сборка мебели. Ремонт.",
    "45": "Диван. Диван-книжка. Угловой диван. Подушки дивана. Кресло. Матрас. Стулья. Ковер.",
    "46": "Уничтожение тараканов, плесени, грибка, клопов, комаров, вшей и других насекомых. Уничтожение мышей, крыс, других вредителей.",
    "48": "Вынос бытового мусора до 70 л (мусорные мешки, пакеты).",

    "49": "Прогрев, разморозка, запуск двигателя в мороз.",
    "50": "Аварийное открытие дверей, багажников, замков.",
    "51": "Диагностика, ремонт электрики, установка магнитол, камер, сигнализаций.",
    "52": "Компьютерная диагностика, проверка сканером, измерение толщиномером.",
    "53": "Ходовая часть, ДВС, КПП, ТНВД, редукторы, рулевые колонки.",
    "54": "Рихтовка, покраска, удаление вмятин, восстановление бамперов.",
    "55": "Балансировка, дошиповка, проверка и настройка колес.",
    "56": "Кузова, фар, салона, нанесение керамики и жидкого стекла.",
    "57": "Установка ПЭТ-пленки, шумоизоляция.",
    "58": "Поиск, доставка запчастей, услуги автоподбора.",
    "59": "Аргонно-дуговая сварка, ремонт алюминиевых деталей.",
    "60": "Буксировка, прикур, подвоз топлива, выездной автосервис.",
    "61": "Изготовление пластиковых запчастей, перетяжка салона.",
    "62": "Услуги очистки портативных гаражей.",

    "63": "Грузоперевозки (по городу, межгород, по России, в улусы). Грузовое такси. Перевозка спецтехники, негабарита. Перевозка грузов по регионам (Саха, Магадан, Чукотка и др.)",
    "64": "Перегон авто по России. Доставка авто с аукционов (Япония, Китай). Профессиональный подбор авто.",
    "65": "Такси межгород (Якутск, Мирный, Ленск и др.). Услуги авто с водителем.",

    "66": "Манипулятор, манипулятор-эвакуатор, манипулятор с разными параметрами стрелы.",
    "67": "Автокран, услуги автокрана, автокраны разных тонн.",
    "68": "Услуги автовышки, аренда автовышки, автогидроподъёмник.",
    "69": "Эвакуатор, эвакуатор-манипулятор, частичная погрузка.",
    "70": "Услуги фекалки, откачка септиков, канализации.",
    "71": "Услуги самосвала, самосвал 18 куб, 3-х кубовый самосвал.",
    "72": "Фронтальный погрузчик, минипогрузчик, экскаватор-погрузчик.",
    "73": "Экскаватор, гусеничный экскаватор, экскаватор-погрузчик.",
    "74": "Услуга буровой, БКМ.",
    "75": "Водовоз, доставка воды, водовозка.",
    "76": "Услуги трала, перевозки тралом.",
    "77": "Аренда генератора, миксеры, бетон.",

    "78": "Такси от пункта А до пункта Б.",
    "79": "Почасовое такси, аренда авто с водителем на час.",
  };

  // 0 - none. 1 - only location. 2 - location and destination
  const locationFields = {
    "1": 1, // Без категории
    "60": 1, // Техпомощь на дороге
    "4": 1, // Электрики
    "5": 1, // Ремонт техники
    "14": 1, // Мастер на час
    "15": 1, // Мебель. Ремонт и изготовление
    "47": 1, // Монтаж систем видеонаблюдения

    "27": 1, // Ремонт квартир и помещений
    "28": 1, // Окна и двери
    "29": 1, // Строительство
    "30": 1, // Газификация
    "31": 1, // Натяжные потолки
    "32": 1, // Автоматические ворота
    "33": 1, // Сварочные работы
    "34": 1, // Отделочные работы
    "35": 1, // Сметы и проектирование

    "37": 1, // Разморозка домов, гаражей, теплотрасс
    "38": 1, // Устранение засоров канализации
    "39": 1, // Установка сантехнического оборудования
    "40": 1, // Монтаж систем отопления и водоснабжения
    "41": 1, // Срочные услуги сантехников и сварщиков

    "66": 1, // Манипуляторы
    "67": 1, // Автокраны
    "68": 1, // Автовышки
    "69": 1, // Эвакуаторы
    "70": 1, // Фекалки и ассенизаторы
    "71": 1, // Самосвалы
    "72": 1, // Погрузчики
    "73": 1, // Экскаваторы
    "74": 1, // Буровые установки
    "75": 1, // Водовозки
    "76": 1, // Тралы и низкорамные платформы
    "77": 1, // Аренда оборудования

    "63": 2, // Грузоперевозки
    "6": 2, // Грузчики
    "64": 0, // Перегон автомобилей
    "65": 2, // Такси межгород
    "36": 2, // Доставка стройматериалов

    "49": 1, // Разогрев авто
    "50": 1, // Вскрытие авто
    "51": 0, // Автоэлектрика
    "52": 0, // Автодиагностика
    "53": 0, // Ремонт автомобилей
    "54": 0, // Кузовной ремонт
    "55": 0, // Шиномонтаж и сход-развал
    "56": 0, // Полировка и химчистка
    "57": 0, // Тонировка и утепление авто
    "58": 0, // Автозапчасти и подбор авто
    "59": 0, // Сварочные работы
    "61": 0, // 3D-печать и кастомизация
    "62": 0, // Сушка портативных гаражей

    "42": 1, // Уборка территорий. Вывоз строительного мусора
    "43": 1, // Вывоз снега
    "44": 1, // Уборка помещений, клининг
    "45": 1, // Химчистка мебели
    "46": 1, // Дезинсекция
    "48": 1, // Вынос бытового мусора

    "78": 2, // Такси по городу
    "79": 1, // Почасовое такси
  };

  const titleHidden = {
    "78": true, // Такси по городу
    "79": true, // Почасовое такси
  };

  const descriptionOptional = {
    "78": true, // Такси по городу
    "79": true, // Почасовое такси
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const compressedFiles = Array(files.length).fill(null);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file) continue;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 1000;
          const maxHeight = 1000;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => {
              compressedFiles[i] = blob;

              if (!compressedFiles.includes(null)) {
                setPhotos(compressedFiles);
                setPhotoUrls(compressedFiles.map((blob) => URL.createObjectURL(blob)));
              }
            },
            "image/jpeg"
          );
        };
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const formData = new FormData();

      if (!titleHidden[categoryId])
        formData.append("title", title);

      formData.append("description", description);
      formData.append("askedPrice", askedPrice);
      formData.append("categoryId", categoryId);

      if (locationFields[categoryId] === 1 || locationFields[categoryId] === 2)
        formData.append("location", location);

      if (locationFields[categoryId] === 2)
        formData.append("destination", destination);

      for (let i = 0; i < photos.length; i++) {
        formData.append("photos", photos[i]);
      }

      const response = id ? await api.put(`/listings/${id}`, formData) : await api.post('/listings', formData);

      const responseData = response.data;
      navigate(`/listing/${responseData.id}`, { replace: true });
    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  const medianTime = {
    "1": "8 мин",
    "4": "3 мин",
    "5": "33 мин",
    "14": "1 мин",
    "15": "23 мин",
    "27": "14 мин",
    "28": "2 мин",
    "29": "762 мин",
    "38": "2 мин",
    "39": "2 мин",
    "41": "5 мин",
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Stack direction='row' spacing={1} alignItems='baseline' sx={{ mb: 2 }}>
        <IconButton color='inherit' size='large' onClick={handleGoBack} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4">{listing ? 'Редактировать заказ' : 'Создать заказ'}</Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth required>
              <InputLabel id="category-select-label" htmlFor="grouped-select">Категория</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={categoryId}
                label="Категория"
                onChange={(e) => setCategoryId(e.target.value)}>
                <MenuItem value=""><em>Выберите категорию</em></MenuItem>

                <ListSubheader>Общие</ListSubheader>
                <MenuItem value="1">Без категории</MenuItem>
                <MenuItem value="60">Техпомощь на дороге</MenuItem>
                <MenuItem value="4">Электрики</MenuItem>
                <MenuItem value="5">Ремонт и установка техники</MenuItem>
                <MenuItem value="14">Мастер на час</MenuItem>
                <MenuItem value="15">Мебель. Ремонт и изготовление</MenuItem>
                <MenuItem value="47">Монтаж систем видеонаблюдения</MenuItem>
                <MenuItem value="80">Социальная парикмахерская</MenuItem>

                <ListSubheader>Транспортировка</ListSubheader>
                <MenuItem value="78">Такси по городу</MenuItem>
                <MenuItem value="79">Почасовое такси</MenuItem>
                <MenuItem value="65">Такси межгород</MenuItem>
                <MenuItem value="6">Грузчики</MenuItem>
                <MenuItem value="63">Грузоперевозки</MenuItem>
                <MenuItem value="64">Перегон автомобилей</MenuItem>
                <MenuItem value="36">Доставка стройматериалов</MenuItem>

                <ListSubheader>Услуги сантехника</ListSubheader>
                <MenuItem value="37">Разморозка домов, гаражей, теплотрасс</MenuItem>
                <MenuItem value="38">Устранение засоров канализации</MenuItem>
                <MenuItem value="39">Установка сантехнического оборудования</MenuItem>
                <MenuItem value="40">Монтаж систем отопления и водоснабжения</MenuItem>
                <MenuItem value="41">Срочные услуги сантехников и сварщиков</MenuItem>

                <ListSubheader>Уборка помещений и территорий</ListSubheader>
                <MenuItem value="42">Уборка территорий. Вывоз строительного мусора</MenuItem>
                <MenuItem value="43">Вывоз снега</MenuItem>
                <MenuItem value="44">Уборка помещений, клининг</MenuItem>
                <MenuItem value="45">Химчистка мебели</MenuItem>
                <MenuItem value="46">Дезинсекция</MenuItem>
                <MenuItem value="48">Вынос бытового мусора</MenuItem>

                <ListSubheader>Строительство и ремонт</ListSubheader>
                <MenuItem value="27">Ремонт квартир и помещений</MenuItem>
                <MenuItem value="28">Окна и двери</MenuItem>
                <MenuItem value="29">Строительство</MenuItem>
                <MenuItem value="30">Газификация</MenuItem>
                <MenuItem value="31">Натяжные потолки</MenuItem>
                <MenuItem value="32">Автоматические ворота</MenuItem>
                <MenuItem value="33">Сварочные работы</MenuItem>
                <MenuItem value="34">Отделочные работы</MenuItem>
                <MenuItem value="35">Сметы и проектирование</MenuItem>

                <ListSubheader>Услуги автосервиса</ListSubheader>
                <MenuItem value="49">Разогрев авто</MenuItem>
                <MenuItem value="50">Вскрытие авто</MenuItem>
                <MenuItem value="51">Автоэлектрика</MenuItem>
                <MenuItem value="52">Автодиагностика</MenuItem>
                <MenuItem value="53">Ремонт автомобилей</MenuItem>
                <MenuItem value="54">Кузовной ремонт</MenuItem>
                <MenuItem value="55">Шиномонтаж и сход-развал</MenuItem>
                <MenuItem value="56">Полировка и химчистка</MenuItem>
                <MenuItem value="57">Тонировка и утепление авто</MenuItem>
                <MenuItem value="58">Автозапчасти и подбор авто</MenuItem>
                <MenuItem value="59">Сварочные работы</MenuItem>
                <MenuItem value="61">3D-печать и кастомизация</MenuItem>
                <MenuItem value="62">Сушка портативных гаражей</MenuItem>

                <ListSubheader>Спецтехника</ListSubheader>
                <MenuItem value="66">Манипуляторы</MenuItem>
                <MenuItem value="67">Автокраны</MenuItem>
                <MenuItem value="68">Автовышки</MenuItem>
                <MenuItem value="69">Эвакуаторы</MenuItem>
                <MenuItem value="70">Фекалки и ассенизаторы</MenuItem>
                <MenuItem value="71">Самосвалы</MenuItem>
                <MenuItem value="72">Погрузчики</MenuItem>
                <MenuItem value="73">Экскаваторы</MenuItem>
                <MenuItem value="74">Буровые установки</MenuItem>
                <MenuItem value="75">Водовозки</MenuItem>
                <MenuItem value="76">Тралы и низкорамные платформы</MenuItem>
                <MenuItem value="77">Аренда оборудования</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {categoryId && medianTime[categoryId] && <Typography variant="body2">Медианное время первого отклика {medianTime[categoryId]}.</Typography>}

          {categoryId && <Typography variant="body2">{catDescriptions[categoryId]}</Typography>}

          {titleHidden[categoryId] || (
            <TextField
              fullWidth
              label="Заголовок (необязательно)"
              value={title}
              multiline
              onChange={(e) => setTitle(e.target.value)}
            />)}

          <TextField
            fullWidth
            label={descriptionOptional[categoryId] ? "Описание (необязательно)" : "Описание"}
            value={description}
            multiline
            onChange={(e) => setDescription(e.target.value)}
            required={!descriptionOptional[categoryId]}
          />
          {locationFields[categoryId] === 1 && (
            <TextField
              fullWidth
              label="Примерный адрес"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          )}
          {locationFields[categoryId] === 2 && (
            <TextField
              fullWidth
              label="Откуда"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          )}
          {locationFields[categoryId] === 2 && (
            <TextField
              fullWidth
              label="Куда"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              required
            />
          )}
          <TextField
            fullWidth
            label="Цена (необязательно)"
            type="number"
            value={askedPrice}
            onChange={(e) => setAskedPrice(e.target.value)}
          />

          <Card>
            <CardHeader title="Фотографии" />

            <CardContent sx={{ px: 0, pt: 0, pb: 2 }}>
              {photoUrls?.length > 0 &&
                <Slider {...settings}>
                  {photoUrls.map((item, index) => (
                    <CardMedia
                      key={index}
                      component="img"
                      image={item}
                      alt="фото заказа" />
                  ))}
                </Slider>
              }
            </CardContent>

            <CardActions>
              <input type="file" accept="image/*" multiple onChange={handleFileChange} />
            </CardActions>
          </Card>

          {error && <Alert severity="error" >{error}</Alert>}

          {isLoading && <LinearProgress />}

          <Button type="submit" color='secondary' fullWidth variant='contained' disabled={isLoading}>
            Готово
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default CreateListing;
