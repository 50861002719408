import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Link,
  Stack,
  Typography,
  Chip,
  Grid2
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchListing } from '../slices/listingsSlice';
import api from '../services/api';
import Mark from "./Mark";
import { useState } from "react";
import PromotedCard from "./PromotedCard";

const Offers = ({ listing }) => {
  const { userId } = useSelector((state) => state.auth);
  const [error, setError] = useState(null);

  const handleAcceptOffer = async (offerId) => {
    setError(null);

    try {
      await api.put(`/offers/${offerId}/accept`);

      dispatch(fetchListing({ id: listing.id }));
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const contact = (masterId, src) => {
    api.post('/masters/contact?masterId=' + masterId + '&source=' + src);
  }

  function offersComparator(a, b) {
    if (a.markTypeIds.includes(1) && !b.markTypeIds.includes(1)) {
      return -1;
    }

    if (!a.markTypeIds.includes(1) && b.markTypeIds.includes(1)) {
      return 1;
    }

    if (a.markTypeIds.includes(2) && !b.markTypeIds.includes(2)) {
      return -1;
    }

    if (!a.markTypeIds.includes(2) && b.markTypeIds.includes(2)) {
      return 1;
    }

    return new Date(a.createdAt) - new Date(b.createdAt);
  }

  const [acceptDialogOfferId, setAcceptDialogOfferId] = useState();

  const dispatch = useDispatch();
  return (
    <>
      <Stack sx={{ pt: 1 }} direction='row' spacing={1} alignItems='baseline'>
        <Typography variant="h5" gutterBottom >Предложения</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => dispatch(fetchListing({ id: listing.id }))} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Stack>

      {!listing.isClosed && userId !== listing.creatorId && <Alert severity="info" >
        <Typography>Чтобы оставить предложение, <Link href={process.env.REACT_APP_MASTER_URL + '/listing/' + listing.id}>переключитесь в режим мастера</Link>.</Typography>
      </Alert>}

      {
        listing.offers?.length > 0 ? (
          [...listing.offers].sort(offersComparator).map((offer) => (
            <PromotedCard key={offer.id} markTypeIds={offer.markTypeIds}>
              <CardHeader
                avatar={<Link component={RouterLink} to={`/master/${offer.phoneNumber}?from=offer`}>
                  <Avatar
                    src={offer.photoUrl}
                    alt={`${offer.name || 'Новый пользователь'} ${offer.lastName || ''}`} />
                </Link>}
                sx={{ pb: 0 }}
                title={<Link component={RouterLink} to={`/master/${offer.phoneNumber}?from=offer`}>{offer.name || 'Новый пользователь'} {offer.lastName || ''}</Link>}
                subheader={
                  <Grid2 container spacing={1} sx={{ mt: 1 }}>
                    {offer.ratingsCount > 0 &&
                      <Grid2 key={0}>
                        <Chip
                          label={`Отзывов: ${offer.ratingsCount}`}
                          size="small"
                          variant='outlined' />
                      </Grid2>}
                    {offer.markTypeIds.map((markTypeId) => (
                      <Grid2 key={markTypeId}>
                        <Mark markTypeId={markTypeId} />
                      </Grid2>
                    ))}
                  </Grid2>}
                action={<Typography variant="caption" color="text.secondary">
                  {new Date(offer.createdAt).toLocaleDateString()}
                </Typography>}
              />
              <CardContent>
                {(offer.price && <Typography variant="subtitle1">{offer.price} руб.</Typography>)}
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
                {offer.phoneNumber &&
                  <Typography>
                    <Link onClick={() => contact(offer.creatorId, 'offer_phone')} href={`tel:${offer.phoneNumber}`} sx={{ mr: 1 }} variant="body1">+{offer.phoneNumber}</Link>
                    /
                    <Link onClick={() => contact(offer.creatorId, 'offer_wa')} href={`https://wa.me/${offer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>WhatsApp</Link>
                    /
                    <Link onClick={() => contact(offer.creatorId, 'offer_tg')} href={`https://t.me/+${offer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>Telegram</Link>
                  </Typography>}
                {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}
              </CardContent>
              <CardActions>
                {listing.creatorId === userId && ((
                  !listing.isClosed &&
                  !listing.hasAcceptedOffer) &&
                  <Button variant='contained' onClick={() => setAcceptDialogOfferId(offer.id)}>Принять</Button>)}
                <Box sx={{ flexGrow: 1 }} />
              </CardActions>
            </PromotedCard>
          ))
        ) : (
          <Typography>Предложений пока нет</Typography>
        )
      }
      <Dialog
        open={acceptDialogOfferId}
        onClose={() => setAcceptDialogOfferId(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleAcceptOffer(acceptDialogOfferId);
            setAcceptDialogOfferId(false);
          },
        }}
      >
        <DialogTitle>Принять предложение</DialogTitle>
        <DialogContent>
          <DialogContentText>
            После принятия заказ будет закрыт, а другие предложения удалены. Продолжить?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setAcceptDialogOfferId(false)} color="primary">
            Отмена
          </Button>
          <Button
            variant='contained'
            type="submit"
            color="secondary"
            autoFocus>
            Принять
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Offers;