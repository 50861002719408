// pages/Listings.js
import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyListings, resetListings } from '../slices/listingsSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Alert,
  Container,
  CardHeader,
  CardActionArea,
  LinearProgress,
  IconButton
} from '@mui/material';
import Category from '../components/Category';
import InstallPwa from '../components/InstallPwa';
import CreateListingCard from '../components/CreateListingCard';
import RefreshIcon from '@mui/icons-material/Refresh';
import ListingsBanner from '../components/ListingsBanner';
import ActiveMasters from '../components/ActiveMasters';

const Listings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listings, loading, reachedEnd, error } = useSelector((state) => state.listings);

  const bottomElementRef = useRef(null);

  const handleScrollEnd = useCallback(() => {
    if (loading) {
      return;
    }

    const createdBefore = listings?.length > 0 ? listings[listings.length - 1].createdAt : undefined;

    dispatch(fetchMyListings({ createdBefore, categoryId: '' }));
  }, [dispatch, listings, loading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleScrollEnd();
        }
      },
      {
        root: null, // Uses viewport as root
        rootMargin: '0px',
        threshold: 1.0, // Trigger when 100% of the element is visible
      }
    );

    const bottomElementRefCurrent = bottomElementRef.current;

    if (bottomElementRefCurrent) {
      observer.observe(bottomElementRefCurrent);
    }

    return () => {
      if (bottomElementRefCurrent) {
        observer.unobserve(bottomElementRefCurrent);
      }
    };
  }, [handleScrollEnd]);

  return (
    <>
      <InstallPwa />
      <ListingsBanner />
      <ActiveMasters />
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          {error && <Alert severity="error">{error}</Alert>}

          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography variant="h4" gutterBottom>Мои заказы</Typography>
            <IconButton onClick={() => dispatch(resetListings())}>
              <RefreshIcon />
            </IconButton>
          </Stack>

          {/* <InstallPwaCard /> */}

          {listings.length === 0 && !loading && <CreateListingCard />}

          {
            listings.map((listing, i) => (
              <>
                <Card
                  key={listing.id}
                  onClick={() => navigate(`/listing/${listing.id}`)}>

                  <CardActionArea>

                    <CardHeader
                      avatar={<Avatar
                        src={listing.photoUrl}
                        alt={listing.name}
                      />}
                      sx={{ pb: 0 }}
                      title={listing.name}
                      action={<Typography variant="caption" color="text.secondary">
                        {new Date(listing.createdAt).toLocaleDateString()}
                      </Typography>}
                    />

                    {
                      listing.photos?.find(p => p.order === 0) &&
                      <CardMedia
                        component="img"
                        image={listing.photos.find(p => p.order === 0).filePath}
                        alt={listing.description}
                        sx={{ maxHeight: '25vh', pt: 2 }}
                      />
                    }

                    <CardContent>
                      <Typography variant="h6" className="lineClamp" gutterBottom>
                        {listing.title}
                      </Typography>
                      <Typography variant="body1" className="lineClamp" gutterBottom>
                        {listing.description}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {listing.location}{listing.destination && ' - '}{listing.destination}
                      </Typography>
                      {listing.askedPrice && <Typography variant="body1" gutterBottom>
                        {listing.askedPrice} руб.
                      </Typography>}
                      <Category categoryId={listing.categoryId} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}

          {!reachedEnd && <LinearProgress ref={bottomElementRef} />}
        </Stack>

      </Container>
    </>
  );
};

export default Listings;
