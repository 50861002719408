import React from "react";
import { Card, CardActionArea, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateListingCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate('/create-listing')}>
      <CardActionArea>
        <CardMedia
          component="img"
          image="/static/client/1/oob.png"
          alt="Создайте заказ" />
      </CardActionArea>
    </Card>
  );
};

export default CreateListingCard;
